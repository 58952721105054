import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'
import PageHelmet from '../components/pageHelmet'

import forBizHeroImg from '../images/hero-couple-umbrella.jpg'
import forBizSvg from '../images/icon-terms.svg'

const faqPage = () => (
  <Layout>
    <PageHelmet
      title="Terms and Conditions"
      description="Terms and Conditions"
      image={forBizHeroImg} />
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Privacy'}
      accentedTitle={'Policy'}
      subtitle={"We're here to answer your questions"}
    />

    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-11 center-block">
            <div className="content">
              <h2>PRIVACY POLICY FOR MASK OF WELLNESS, LLC</h2>
              <h5>UPDATED May 22, 2020</h5>
              <p>
                This privacy policy governs your use of the website, www.MaskofWellness.com, (“Application”) for any applications (“apps”) and website that are created by Mask of Wellness, LLC (“Licensor”). The Application includes any apps and website promoting the business of the Licensor.
              </p>
              <h5>User Provided Information</h5>
              <p>
                The Application obtains the information you provide when you use or download and register the Application. Registration with us is optional. Registration with us is mandatory in order to be able to use the features of the Application.  Registration will require the disclosure of personal or business information for purposes of establishing an account and granting access to the Application features.  Licensor is committed to protect all confidential information that may be disclosed.  You have certain rights to your personal/business information as further set forth in this Privacy Policy.
                We may also use the information you provided us to contact you from time to time to provide you with important information, required notices and marketing promotions.
              </p>
              <h5>Automatically Collected Information</h5>
              <p>
                In addition, the Application may collect certain information automatically, including, but not limited to, the type of device you use, your devices unique device ID, the IP address of your device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.
              </p>
              <h5>Location Information</h5>
              <p>
                When you visit the application, we may use GPS technology (or other similar technology) to determine your current location in order to determine the city you are located within and display a location map with relevant advertisements. We will not share your current location with other users or partners.
              </p>
              <p>
                You can turn off the location functionality at any time by turning off the location services setting for the mobile application on your device.  If you choose to use the location function, you consent to Licensor and our licensees’ transmission, collection, maintenance, processing and use of your location data and queries to improve and provide location-based products and services.  You may withdraw this consent by turning off the location services setting on your device.
              </p>
              <h5>Third Party Access</h5>
              <p>
                We will share your information with third parties only in the ways that are described in this privacy statement.
              </p>
              <p>We may disclose User Provided and Automatically Collected Information:
                <ul>
                  <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
                  <li>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
                  <li>with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
                  <li>to provide to professionals working with Licensor for purposes of securing and evaluating insurance, protecting the Application, ensuring development and quality control, managing risk, business management and addressing claims.</li>
                  <li>to process financial transactions as may be provided in Licensor’s website.  Licensor may use a third-party to process financial transactions, and such transaction shall be governed by the provisions of the third-party processing company.  You agree to comply with the rules of the third-party processor and acknowledge that you are subject to all privacy policies that may be disclosed on said website.</li>
                  <li>Mask of Wellness, LLC intends to transfer rights to Mask of Wellness, LLC and will not notify you directly and will be noted on the website at a future date.  Otherwise, if Licensor is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information.  </li>
                  <li>to advertisers and third-party advertising networks and analytics companies as described in the section below.</li>
                </ul>
              </p>
              <h5>Opt-out</h5>
              <p>
                There are opt-out options for users of this Application:
                <br/>
                <u>Opt-out of all information collection by uninstalling the Application:</u> You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your device or via the application marketplace or network.
              </p>
              <h5>Data Retention Policy, Managing Your Information</h5>
              <p>
                We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to 24 months and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided via the Application, please contact us at support@maskofwellness.com and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.  Further, rights that you may exercise relative to your personal information are set forth below.
              </p>
              <h5>Children</h5>
              <p>
                We do not use the Application to knowingly solicit data from or market to children under the age of 13. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at support@maskofwellness.com and we will delete such information from our files within a reasonable time.
              </p>
              <h5>Security</h5>
              <p>
                We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve our Application. Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.
              </p>
              <h5>Changes</h5>
              <p>
                This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at Maskofwellness.com. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
              </p>
              <h5>Your Consent</h5>
              <p>
                By using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information that you have made available through use of the Application, all of which activities will take place in the United States of America. If you reside outside the United States, your information will be transferred, processed and stored within the United States of America and will be governed by the laws of the United States of America and under United States privacy standards.
              </p>
              <h5>Your Rights</h5>
              <p>
                Your principal rights regarding protection of your data are:
                (a)	the right to access;<br />
                (b)	the right to rectification;<br />
                (c)	the right to erasure;<br />
                (d)	the right to restrict processing;<br />
                (e)	the right to object to processing;<br />
                (f)	the right to data portability;<br />
                (g)	the right to complain to a supervisory authority; and<br />
                (h)	the right to withdraw consent.
              </p>
              <p>
                You have the right to confirmation as to whether or not we process your personal data and, where we do, access to the personal data, together with certain additional information. That additional information includes details of the purposes of the processing, the categories of personal data concerned and the recipients of the personal data. Providing the rights and freedoms of others are not affected, we will supply to you a copy of your personal data.  Any errors in your personal information will be corrected upon notification.
              </p>
              <p>
                In some circumstances you may have the right to delete your personal data without delay. Those circumstances include: the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed; you withdraw consent to consent-based processing; you object to the processing under certain rules of applicable data protection law; the processing is for direct marketing purposes; and the personal data have been unlawfully processed. However, there are exclusions of the right to erasure. The general exclusions include where processing is necessary: for compliance with a legal obligation; or for the establishment, exercise or defense of legal claims.
              </p>
              <p>
                You have the right to object to our processing of your personal data on grounds relating to your particular situation, but only to the extent that the legal basis for the processing is that the processing is necessary for: the performance of a task carried out in the public interest or in the exercise of any official authority vested in us; or the purposes of the legitimate interests pursued by us or by a third party. If you make such an objection, we will cease to process the personal information unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or the processing is for the establishment, exercise or defense of legal claims.
              </p>
              <p>
                You have the right to object to our processing of your personal data for direct marketing purposes (including profiling for direct marketing purposes). If you make such an objection, we will cease to process your personal data for this purpose.
              </p>
              <p>
                You have the right to object to our processing of your personal data for scientific or historical research purposes or statistical purposes on grounds relating to your particular situation, unless the processing is necessary for the performance of a task carried out for reasons of public interest.
              </p>
              <p>
                To the extent that the legal basis for our processing of your personal information is consent, you have the right to withdraw that consent at any time. Withdrawal will not affect the lawfulness of processing before the withdrawal.
              </p>
              <h5>Contact Us</h5>
              <p>
                If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at support@maskofwellness.com.  This website is owned by Mask of Wellness, LLC which is a registered corporation under the laws of the State of Iowa.
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default faqPage
